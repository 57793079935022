import React from "react";
import PropTypes from "prop-types";
import LibModal from "react-modal";
import cx from "classnames";
import Icon from "../Icon/Icon";
import style from "./Modal.scss";

const closeIcon = require("../../assets/icons/close.svg");

const Modal = ({
  onClose,
  title,
  footer,
  children,
  closeable,
  className,
  overlayClassName,
  ...props
}) => {
  const modalProps = {
    ...props,
    className: cx(style.modal, className),
    overlayClassName: cx(style.overlay, overlayClassName)
  };

  const contentClass = footer
    ? style.modalContentWithFooter
    : style.modalContent;

  return (
    <LibModal {...modalProps}>
      <div className={style.modalHead}>
        <h2>{title}</h2>
        {closeable && (
          <Icon
            className={style.modalClose}
            svg={closeIcon}
            onClick={onClose}
          />
        )}
      </div>
      <div className={contentClass}>{children}</div>
      {footer && <div className={style.modalFooter}>{footer}</div>}
    </LibModal>
  );
};

Modal.defaultProps = {
  footer: null,
  className: null,
  overlayClassName: null,
  closeable: false
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  closeable: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  footer: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired
};

export default Modal;
