import React from "react";
import PropTypes from "prop-types";
import SVGInline from "react-svg-inline";
import classnames from "classnames";

import styles from "./Icon.scss";

const Icon = ({ className, ...props }) => (
  <SVGInline {...props} className={classnames(styles.Icon, className)} />
);

Icon.defaultProps = {
  className: null
};

Icon.propTypes = {
  className: PropTypes.string,
  svg: PropTypes.string.isRequired
};

export default Icon;
