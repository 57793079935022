import React from "react";
import PropTypes from "prop-types";

import LoadingIndicator from "../LoadingIndicator";
import ErrorIndicator from "../ErrorIndicator";
import LocationChooserLegend from "../LocationChooserLegend";

import styles from "./LocationChooser.scss";

const LocationChooser = ({ error, loading, submit, list, ...props }) => {
  const { selected } = props;
  if (error) {
    return <ErrorIndicator message={error.message} />;
  }

  const children = loading ? (
    <LoadingIndicator message="Loading locations..." />
  ) : (
    <>
      <p>
        This will export reusable settings such as layout, label, active
        measurement signals, etc to a selected level. All stages under this
        level will use these settings.
      </p>
      <LocationChooserLegend />
      {list(props)}
      <button type="button" onClick={submit} disabled={!selected}>
        Submit
      </button>
    </>
  );

  return <div className={styles.chooser}>{children}</div>;
};

LocationChooser.propTypes = {
  error: PropTypes.shape({ message: PropTypes.string }),
  loading: PropTypes.bool,
  submit: PropTypes.func,
  list: PropTypes.func.isRequired,
  selected: PropTypes.shape({})
};

LocationChooser.defaultProps = {
  error: undefined,
  loading: false,
  submit: () => false,
  selected: undefined
};

export default LocationChooser;
