import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Icon from "../Icon";
import styles from "./LoadingIndicator.scss";

const loadingIcon = require("../../assets/icons/loading.svg");

const LoadingIndicator = ({ message, className }) => (
  <div className={classnames(styles.indicator, className)}>
    <Icon className={styles.icon} svg={loadingIcon} />
    <span className={styles.message}>{message}</span>
  </div>
);

LoadingIndicator.defaultProps = {
  message: "",
  className: undefined
};

LoadingIndicator.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string
};

export default LoadingIndicator;
