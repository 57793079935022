import * as actionTypes from "./actionTypes";

export const initialState = {
  exporting: false,
  stage: null,
  settings: null,
  exportError: null,
  exportModalOpen: false,
  exportSuccess: false,
  importModalOpen: false,
  importing: false,
  imported: null,
  importError: null
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  console.log("reducer(", state, ",", action, ")");
  switch (type) {
    case actionTypes.SET_STAGE:
      return {
        ...state,
        stage: payload
      };
    case actionTypes.EXPORT_REQUEST:
      return {
        ...state,
        settings: payload.settings,
        exportModalOpen: true,
        exportSuccess: false
      };
    case actionTypes.EXPORT_SUCCESS:
      return {
        ...state,
        settings: null,
        exporting: false,
        exportSuccess: true,
        exportModalOpen: false
      };
    case actionTypes.EXPORT_ERROR:
      return {
        ...state,
        settings: null,
        exportModalOpen: false,
        exportError: payload,
        exportSuccess: true
      };
    case actionTypes.IMPORT_REQUEST:
      return {
        ...state,
        importModalOpen: true,
        importing: true,
        imported: null
      };
    case actionTypes.IMPORT_SUCCESS:
      return {
        ...state,
        imported: action.payload.stage,
        importName: action.payload.name,
        importModalOpen: false,
        importSuccess: true,
        importing: false
      };
    case actionTypes.IMPORT_ERROR:
      return {
        ...state,
        importing: false,
        importModalOpen: false,
        importError: action.payload
      };
    default:
      return state;
  }
};
