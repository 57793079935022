import * as actionTypes from "./actionTypes";

export const setStage = stage => ({
  type: actionTypes.SET_STAGE,
  payload: stage
});

export const exportRequest = settings => ({
  type: actionTypes.EXPORT_REQUEST,
  payload: { settings }
});
export const exportSuccess = () => ({ type: actionTypes.EXPORT_SUCCESS });
export const exportError = error => ({
  type: actionTypes.EXPORT_ERROR,
  payload: error
});

export const importRequest = () => ({
  type: actionTypes.IMPORT_REQUEST
});

export const importSuccess = (name, stage) => ({
  type: actionTypes.IMPORT_SUCCESS,
  payload: {
    name,
    stage
  }
});
export const importError = error => ({
  type: actionTypes.IMPORT_ERROR,
  payload: error
});
