import React from "react";
import PropTypes from "prop-types";
import Icon from "../Icon";

import styles from "./ErrorIndicator.scss";

const alertIcon = require("../../assets/icons/alert-circle.svg");

const ErrorIndicator = ({ message }) => (
  <div className={styles.ErrorIndicator}>
    <Icon className={styles.icon} svg={alertIcon} />
    <span className={styles.message}>{message}</span>
  </div>
);

ErrorIndicator.defaultProps = {
  message: null
};

ErrorIndicator.propTypes = {
  message: PropTypes.string,
  theme: PropTypes.shape({
    ErrorIndicator: PropTypes.string,
    icon: PropTypes.string,
    message: PropTypes.string
  }).isRequired
};

export default ErrorIndicator;
