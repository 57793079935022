/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import * as R from "ramda";
import { connect } from "react-redux";
import {
  ApiProvider,
  MeasurementSelector,
  FileSelector
} from "insync-stage-importer";
import Modal from "../Modal";
import LoadingIndicator from "../LoadingIndicator";
import * as constants from "../../constants";

import styles from "./ImportStageModal.scss";

import { importSuccess, importError } from "../../actions";

const mapStateToProps = ({ stage, importModalOpen, ...state }) => {
  return {
    stage,
    modalOpen: importModalOpen
  };
};

const mapDispatchToProps = dispatch => ({
  onFinish: (...args) => dispatch(importSuccess(...args)),
  onCancel: () => dispatch(importError(new Error("Cancelled by user.")))
});

const DOMImporter = ({ chooser, saving, ...props }) => {
  const children = saving ? (
    <LoadingIndicator message="Importing..." />
  ) : (
    chooser(props)
  );
  return <div className={styles.importer}>{children}</div>;
};

DOMImporter.propTypes = {
  chooser: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired
};

const stageName = stage =>
  R.pipe(
    R.split("/"),
    R.last(),
    R.split("."),
    R.nth(0)
  )(stage);

const validate = rules => input => {
  const errors = R.pipe(
    R.map(R.applyTo(input)),
    R.filter(R.identity)
  )(rules);
  console.log("errors = ", errors);
  return {
    isValid: R.isEmpty(errors),
    errors
  };
};

const rule = R.curry((pred, msg, val) => (pred(val) ? undefined : msg));

const validateComparisonName = validate([
  rule(R.complement(R.isEmpty), "Comparison name must not be empty."),
  rule(
    R.test(/^[a-zA-Z0-9_]{1,}$/),
    "Comparison name may only contain alphanumeric characters and underscores."
  )
]);

// TODO: Implement reducer instead of different state variables.
const ImportStageModal = ({ modalOpen, onCancel, onFinish, stage }) => {
  const [measurement, setMeasurement] = useState(null);
  const [selectedStage, setSelectedStage] = useState(null);
  const [error, setError] = useState(null);
  const [comparisonName, setComparisonName] = useState("comparison");
  const [comparisonNameErrors, setComparisonNameErrors] = useState([]);

  const handleComparisonNameChange = e => {
    setComparisonName(e.target.value);
    const { errors } = validateComparisonName(e.target.value);
    setComparisonNameErrors(errors);
  };

  const showErrors = !R.isEmpty(comparisonNameErrors);

  const comparisonStageName = `${stageName(stage)}_${comparisonName}`;

  const finalize = () => {
    onFinish(comparisonStageName, selectedStage);
  };

  return (
    <Modal
      isOpen={modalOpen}
      title="Compare stage"
      onClose={onCancel}
      closeable
      className={styles.modal}
    >
      <div className={styles.content}>
        <p>
          This tool allows you to import another stage into the current stage
          for comparison. You will need to specify a name for your comparison
          and select another measurement. Please note that measurements with
          other model scales will <b>not</b> be selectable in this version of
          the stageplayer.
        </p>
        <p>
          After importing, the timelines of the current stage and imported stage
          will be synchronised based on the earliest assets of both stages.
        </p>
        <p>
          Also please note that creating a comparison will <b>not</b>{" "}
          automatically save this comparison to inSync. In order to save your
          comparison, please use the &quot;save&quot; function provided in the
          &quot;File&quot; menu. This will write the current state of the stage
          to the stage file name specified in the comparison name input.
        </p>
        <form>
          <div className="form-group">
            <label htmlFor="comparisonName">
              <b>Specify a name for your comparison</b>
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  {`${stageName(stage)}_`}
                </span>
              </div>
              <input
                type="text"
                className={cx("form-control", {
                  "is-valid": R.isEmpty(comparisonNameErrors),
                  "is-invalid": !R.isEmpty(comparisonNameErrors)
                })}
                id="comparisonName"
                placeholder="Please specify a name for your comparison."
                onChange={handleComparisonNameChange}
                value={comparisonName}
              />
              <div className="input-group-append">
                <span className="input-group-text">.stage</span>
              </div>
            </div>
          </div>
          {showErrors && (
            <div className="alert alert-danger">
              <b>Input is not correct, please review following errors:</b>
              <ul>
                {R.map(
                  e => (
                    <li>{e}</li>
                  ),
                  comparisonNameErrors
                )}
              </ul>
            </div>
          )}
          {error && <div className="alert alert-danger">{`${error}`}</div>}
          <ApiProvider endpoint={constants.PYDIO_ENDPOINT} stage={stage}>
            <b>
              Please select another stage to compare the current stage to. All
              the assets in the other stage will be loaded into a separate
              timeline.
            </b>
            <MeasurementSelector
              menuPosition="fixed"
              renderSelector={(level, children) => {
                if (level === "program") {
                  const [facility, program] = children;
                  return (
                    <>
                      <div className={styles.facility}>{facility}</div>
                      <div className={styles.program}>{program}</div>
                    </>
                  );
                }
                return children;
              }}
              onChange={setMeasurement}
              onError={setError}
            >
              {([
                projectSelector,
                programSelector,
                categorySelector,
                testSelector,
                experimentSelector,
                measurementSelector
              ]) => (
                <div className={styles.levelSelector}>
                  <div className={styles.projectSelector}>
                    {projectSelector}
                  </div>
                  <div className={styles.programSelector}>
                    {programSelector}
                  </div>
                  <div className={styles.categorySelector}>
                    {categorySelector}
                  </div>
                  <div className={styles.testSelector}>{testSelector}</div>
                  <div className={styles.experimentSelector}>
                    {experimentSelector}
                  </div>
                  <div className={styles.measurementSelector}>
                    {measurementSelector}
                  </div>
                </div>
              )}
            </MeasurementSelector>
            <div className={styles.row}>
              <div className={styles.selector}>
                <FileSelector
                  menuPosition="fixed"
                  placeholder="Select a stage"
                  measurement={measurement}
                  onChange={setSelectedStage}
                  onError={setError}
                />
              </div>
            </div>
            <button
              className="btn btn-primary"
              type="button"
              onClick={finalize}
              disabled={!selectedStage || !R.isEmpty(comparisonNameErrors)}
            >
              Compare
            </button>
          </ApiProvider>
        </form>
      </div>
    </Modal>
  );
};

ImportStageModal.propTypes = {
  api: PropTypes.shape({}).isRequired,
  modalOpen: PropTypes.bool,
  settings: PropTypes.shape({}),
  onCancel: PropTypes.func,
  onFinish: PropTypes.func,
  stage: PropTypes.string.isRequired
};

ImportStageModal.defaultProps = {
  settings: null,
  modalOpen: false,
  onCancel: R.F,
  onFinish: R.F
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportStageModal);
