import React from "react";
import styles from "./LocationChooserLegend.scss";

const LocationChooserLegend = () => (
  <div className={styles.legend}>
    <div className={styles.entry}>
      <div className={styles.termUsed} />
      <div className={styles.desc}>
        The current settings used for this stage are store here.
      </div>
    </div>
    <div className={styles.entry}>
      <div className={styles.termSet} />
      <div className={styles.desc}>
        Settings are already stored at this location.
      </div>
    </div>
    <div className={styles.entry}>
      <div className={styles.termNotAvailable} />
      <div className={styles.desc}>
        Not available because a lower level already has settings stored.
      </div>
    </div>
  </div>
);

export default LocationChooserLegend;
