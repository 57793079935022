/* eslint-disable jsx-a11y/label-has-for */
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./LocationsListItem.scss";

const LocationsListItem = ({ location, clear, selected, onSelect }) => {
  const {
    id,
    available,
    clearable,
    type,
    used,
    set,
    selectable,
    formatted
  } = location;

  const clearLocation = clear(location);
  const onClickClear = (...args) => {
    // eslint-disable-next-line no-alert
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Do you really want to clear these settings?")) {
      clearLocation(...args);
    }
  };

  const selectionComponent = (
    <div className={styles.selectionComponent}>
      {selectable ? (
        <input
          type="radio"
          id={id}
          name="chosenLocation"
          onChange={onSelect(location)}
          disabled={!available}
          checked={selected}
        />
      ) : null}
    </div>
  );

  return (
    <div
      className={classnames(styles.item, {
        [styles.notAvailable]: !available,
        [styles.used]: used,
        [styles.set]: set
      })}
    >
      {selectionComponent}
      <label htmlFor={id} className={styles.label}>
        {` ${type} - ${formatted}`}
        {clearable && (
          <button
            className={styles.clearButton}
            type="button"
            onClick={onClickClear}
          >
            clear
          </button>
        )}
      </label>
    </div>
  );
};

LocationsListItem.propTypes = {
  location: PropTypes.shape({
    id: PropTypes.string.isRequired,
    available: PropTypes.bool.isRequired,
    set: PropTypes.bool.isRequired,
    used: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    clearable: PropTypes.bool,
    type: PropTypes.string.isRequired
  }).isRequired,
  clear: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default LocationsListItem;
