/* eslint-disable prefer-destructuring */
/* global marin:false */
import "symbol-observable";
import React from "react";
import { from as observableFrom, race } from "rxjs";
import { first } from "rxjs/operators";
import ReactDOM from "react-dom";
import querystring from "query-string";
import ReactModal from "react-modal";
import * as R from "ramda";
import { sprintf } from "sprintf-js";
import path from "path";
import { createApi } from "insync-settings-api-exporter";
import { SignalViewerDataClient } from "@insync-stageplayer/measurements";
import { exportRequest, importRequest, setStage } from "./actions";

import { STAGE_TEMPLATE, FILE_TEMPLATE, PYDIO_ENDPOINT, AUTHENTICATION_ENDPOINT } from "./constants";

import App from "./components/App";
import { createStore } from "./store";

import "./index.css";

const SBF_DATA_ENDPOINT = process.env.SBF_DATA_ENDPOINT;
const DATA_ENDPOINT =
  (window.location.protocol === "https:" ? "wss://" : "ws://") +
  window.location.hostname +
  process.env.DATA_ENDPOINT;

const measurementDataClient = new SignalViewerDataClient({
  hdfEndpoint: DATA_ENDPOINT,
  sbfEndpoint: SBF_DATA_ENDPOINT
});

const api = createApi(process.env.API_HOST);

const reactTarget = document.getElementById("root");

const loadingHandler = import("insync-stage-handler").then(
  ({ createStageHandler, createStageSettingsHandler }) => ({
    stageHandler: createStageHandler(),
    settingsHandler: createStageSettingsHandler()
  })
);

ReactModal.setAppElement(reactTarget);

const isComparison = s => {
  return R.pathSatisfies(
    ids => ids.length > 1,
    ["timeline", "entities", "timeline", "ids"],
    s
  );
};
const relativeProjectAssetPattern = /^[0-9]{2}xxx\/[0-9]{5}\//gm;
const defaultArgs = {
  target: "#stageplayer"
};

const paramsStr =
  window.location.search.indexOf("?") === 0
    ? window.location.search.substring(1)
    : window.location.search;

const params = querystring.parse(paramsStr);
const { stage = "30290_04SMB_02_009_001_03.stage", time = null } = params;

const store = createStore(stage);
const store$ = observableFrom(store);

function Controller({ setTime }) {
  const timeNo = parseInt(time, 0);
  if (!Number.isNaN(timeNo)) {
    setTime(parseFloat(time));
  }
}

// Stages follow several naming patterns at the moment, this normalizes them.
const fixPaths = s => ({
  ...s,
  files: {
    ...s.files,
    entities: {
      ...s.files.entities,
      files: {
        ...s.files.entities.files,
        byId: s.files.entities.files.ids.reduce((acc, id) => {
          const file = s.files.entities.files.byId[id];
          let filePath = file.path;

          if (relativeProjectAssetPattern.test(filePath)) {
            filePath = `/${filePath}`;
          }

          const newPath = path.isAbsolute(filePath) ? `~${filePath}` : filePath;

          return {
            ...acc,
            [id]: {
              ...file,
              path: newPath
            }
          };
        }, {})
      }
    }
  }
});

const mergeStageVals = R.curry((stageVal, settingsVal) => {
  if (R.is(Object, stageVal) && R.is(Object, settingsVal)) {
    return R.merge(settingsVal, stageVal);
  }
  return stageVal;
});

const mergeWithSettings = async (stageFileURI, stageJSON) => {
  let result;
  try {
    result = await api.fetchStageSettings(stageFileURI);
  } catch (err) {
    if (err) {
      console.log("Respose: ", result);
      console.log("error: ", err);
      throw err;
    }
    result = await api.fetchStageSettings();
  }

  const { settingsHandler } = await loadingHandler;

  const handledSettings = settingsHandler(stageJSON, result.settings);

  const merged = R.mergeWith(mergeStageVals, stageJSON)(handledSettings);
  return merged;
};

const handleWithStageHandler = s =>
  loadingHandler.then(({ stageHandler }) => stageHandler(s));

const fetchStageById = id => {
  const stageURL = sprintf(STAGE_TEMPLATE, id);
  return fetch(stageURL, { credentials: "include" })
    .then(response => {
      if (response.status === 200) {
        return response.json();
      }
      else if (response.status === 403) {
        const { pathname, search } = window.location;
        const uri = `${pathname}${search}`;
        const redirectParams = { referer: uri };
        window.location = `${AUTHENTICATION_ENDPOINT}?${querystring.stringify(
          redirectParams
        )}`;
      } else {
        return response.json().then(json => {
          return Promise.reject(new Error(json.message));
        });
      }
    })
    .then(R.tap(json => console.log("JSON = ", json)))
    .then(fixPaths)
    .then(R.tap(fixed => console.log("fixed paths = ", fixed)))
    .then(stageJSON => mergeWithSettings(stage, stageJSON))
    .then(R.tap(merged => console.log("merged = ", merged)))
    .then(handleWithStageHandler)
    .then(R.tap(handled => console.log("handled = ", handled)))
    .then(({ stage: s, error }) => {
      if (error) throw error;
      return s;
    });
};

const fetchStage = async () => {
  const s = await fetchStageById(stage);
  console.log("fetched stage = ", s);
  return R.pipe(
    R.ifElse(
      isComparison,
      R.assocPath(["menu", "allowedActions"], ["save"]),
      R.assocPath(["menu", "allowedActions"], [])
    )
  )(s);
};

const liveStage = () => {}

const saveAs = () => Promise.resolve();

async function callPydioFn(formData) {
  const response = await fetch(PYDIO_ENDPOINT, {
    method: "POST",
    credentials: "include",
    body: formData
  });

  const { status } = response;
  const json = await response.json();

  if (status !== 200) throw new Error(json.message);

  return json;
}

async function* save({ content }) {
  const { stage: cStage } = store.getState();
  yield { message: "Saving..." };
  const formData = new FormData();
  formData.append("get_action", "save_stage");
  formData.append("repo", "insync");
  formData.append("file", cStage);
  formData.append("content", JSON.stringify(content));

  const result = await callPydioFn(formData);

  yield { message: "Finished saving" };
  return result;
}

async function* saveFragment({ fragment }) {
  const { stage: cStage } = store.getState();

  yield { message: "Saving fragment..." };
  const formData = new FormData();
  formData.append("get_action", "save_fragment");
  formData.append("repo", "insync");
  formData.append("file", cStage);
  formData.append("fragment", JSON.stringify(fragment));

  const result = await callPydioFn(formData);

  yield { message: "Finished saving fragment" };

  return result;
}

/*
function saveAs({ from, content }) {
  yield { message: "Saving as..." };
  const formData = new FormData();
  formData.append("get_action", "save_fragment");
  formData.append("repo", "insync");
  formData.append("file", from);
  formData.append("fragment", JSON.stringify(fragment));

  const result = await callPydioFn(formData);

  yield { message: "Finished saving fragment" };

  return result;
}
*/

async function* exportSettings(args) {
  console.log("Export settings", args);
  const { content } = args;
  yield { message: "Opening export dialog." };
  store.dispatch(exportRequest(content));
  const success = store$.pipe(first(({ exportSuccess }) => exportSuccess));
  const error = store$.pipe(first(({ exportError }) => exportError));

  const finish = race(success, error);
  const result = await finish.toPromise();

  if (result.exportError) {
    throw result.exportError;
  }

  yield { message: "Finished saving settings!" };
  return result;
}

export async function* recordStagePlayer(initialRecordTime, endRecordTime, rate) {
  yield { message: "Initializing recording" };

  let stageBasename = stage.substring(stage.lastIndexOf("/")+1);
  stageBasename = stageBasename.substring(0, stageBasename.lastIndexOf(".stage"));

  const scripts = document.getElementsByTagName("script");
  let found = false;
  for (let i = 0; i < scripts.length; i += 1) {
    if (scripts[i].src.endsWith("/frontendapi?action=get_recorder")) {
      found = true;
      break;
    }
  }

  if (!found) {
    const script = document.createElement("script");
    script.async = false;
    script.defer = false;
    script.src = "/frontendapi?action=get_recorder";
    script.setAttribute("crossorigin", "use-credentials");
    document.head.appendChild(script);

    script.onload = () => {
      window.startScreenGrab({
        videoid: stageBasename,
        start: initialRecordTime,
        end: endRecordTime,
        playoutrate: rate
      });
    };
  } else {
    window.startScreenGrab({
      videoid: stageBasename,
      start: initialRecordTime,
      end: endRecordTime,
      playoutrate: rate
    });
  }
}

const resolveFileURI = R.curry((fromStage, file) => {
  const stagepath = path.dirname(fromStage);
  const { path: filePath } = file;
  const resolved = path.resolve(stagepath, filePath);
  if (!(resolved.endsWith(".sbf") || resolved.endsWith(".h5m"))) {
    return sprintf(FILE_TEMPLATE, resolved);
  }
  return resolved;
});

async function importStage() {
  store.dispatch(importRequest());
  const success = store$.pipe(first(({ importSuccess }) => importSuccess));
  const error = store$.pipe(first(({ importError }) => importError));

  const finish = race(success, error);
  const {
    importName,
    imported: stageId,
    importError
  } = await finish.toPromise();

  if (importError) {
    throw importError;
  }
  const { stage: cStage } = store.getState();
  const stagepath = path.dirname(cStage);
  const newStageName = path.resolve(stagepath, `${importName}.stage`);
  store.dispatch(setStage(newStageName));

  const importedStage = await fetchStageById(stageId);

  const withResolvedFiles = R.pipe(
    R.unless(
      R.hasPath(["files", "entities", "files"]),
      R.assocPath(["files", "entities", "files"], {
        ids: [],
        byId: {}
      })
    ),
    R.over(
      R.lensPath(["files", "entities", "files", "byId"]),
      R.map(f => ({
        ...f,
        resolvedPath: resolveFileURI(stage, f)
      }))
    ),
    R.assocPath(["menu", "allowedActions"], ["save"])
  )(importedStage);

  return withResolvedFiles;
}

async function getUser() {
  const formData = new FormData();
  formData.append("get_action", "get_user");
  formData.append("repo", "insync");

  const response = await callPydioFn(formData);

  return response.id || "no-user";
}
const generateLink = forTime => {
  const { protocol, host, pathname } = window.location;

  const secureToken = "secure_token";
  const { [secureToken]: token, ...usedParams } = params;

  const newParams = {
    ...usedParams,
    time: Math.floor(forTime)
  };

  return `${protocol}//${host}${pathname}?${querystring.stringify(newParams)}`;
};

function initStagePlayer() {
  console.info(`inSync StagePlayer Embed ${process.env.VERSION}`);
  const args = {
    ...defaultArgs,
    ...params,
    fetchStage,
    save,
    saveFragment,
    saveAs,
    exportSettings,
    getUser,
    resolveFileURI: resolveFileURI(stage),
    measurementDataClient,
    importStage,
    controller: Controller,
    timeline: {
      generateLink
    },
    error: !stage ? "No stage file to open." : undefined,
    liveStage,
    recordStagePlayer
  };

  marin.StagePlayer(args);
  ReactDOM.render(<App api={api} store={store} />, reactTarget);
}

document.addEventListener("DOMContentLoaded", initStagePlayer);
