import React from "react";
import { pipe, map } from "ramda";

import styles from "./LocationsList.scss";

export default ({ items, ...props }) =>
  pipe(
    map(({ component, key }) => (
      <li className={styles.listItem} key={key}>
        {component(props)}
      </li>
    )),
    listItems => <ul className={styles.list}>{listItems}</ul>
  )(items);
