import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as exporter from "insync-settings-api-exporter";
import Modal from "../Modal";
import LoadingIndicator from "../LoadingIndicator";
import LocationChooser from "../LocationChooser";
import LocationsList from "../LocationsList";
import { exportSuccess, exportError } from "../../actions";
import LocationsListItem from "../LocationsListItem";
import styles from "./ExportSettingsModal.scss";

const { SettingsExporter } = exporter;

const mapStateToProps = ({ stage, exportModalOpen, settings }) => ({
  stage,
  modalOpen: exportModalOpen,
  settings
});

const mapDispatchToProps = dispatch => ({
  onFinish: () => dispatch(exportSuccess()),
  onCancel: () => dispatch(exportError(new Error("Cancelled by user.")))
});

const DOMExporter = ({ chooser, saving, ...props }) => {
  const children = saving ? (
    <LoadingIndicator message="Exporting..." />
  ) : (
    chooser(props)
  );
  return <div className={styles.exporter}>{children}</div>;
};

DOMExporter.propTypes = {
  chooser: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired
};

export const ExportSettingsModal = ({
  api,
  modalOpen,
  settings,
  onFinish,
  onCancel,
  stage
}) => (
  <Modal
    isOpen={modalOpen}
    title="Export stagesettings"
    onClose={onCancel}
    closeable
  >
    <div className={styles.content}>
      <SettingsExporter
        api={api}
        stage={stage}
        settings={settings}
        onFinish={onFinish}
        render={props => <DOMExporter {...props} />}
        renderChooser={props => <LocationChooser {...props} />}
        renderList={props => <LocationsList {...props} />}
        renderListItem={props => <LocationsListItem {...props} />}
      />
    </div>
  </Modal>
);

ExportSettingsModal.propTypes = {
  api: PropTypes.shape({}).isRequired,
  stage: PropTypes.string,
  modalOpen: PropTypes.bool,
  settings: PropTypes.shape({}),
  onFinish: PropTypes.func,
  onCancel: PropTypes.func
};

ExportSettingsModal.defaultProps = {
  stage: null,
  settings: null,
  modalOpen: false,
  onFinish: () => false,
  onCancel: () => false
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportSettingsModal);
