import React from "react";

import PropTypes from "prop-types";
import { Provider } from "react-redux";
import ConnectedExportSettingsModal from "./ExportSettingsModal";
import ImportStageModal from "./ImportStageModal";

const Container = ({ store, ...props }) => {
  return (
    <Provider store={store}>
      <ConnectedExportSettingsModal {...props} />
      <ImportStageModal {...props} />
    </Provider>
  );
};

const App = props => {
  return <Container {...props} />;
};

Container.propTypes = {
  store: PropTypes.shape({}).isRequired
};

export default App;
